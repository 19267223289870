<template>
  <div class="page" style="max-width: 600px; margin: 0 auto;">
    <div style="height:4px"></div>
    <div class="tabbox">
      <div :class="['tabitem', ywType == 1 ? 'tabckd' : '']" @click="setCkd(1)">代理业务</div>

      <div :class="['tabitem', ywType == 2 ? 'tabckd' : '']" @click="setCkd(2)">增值服务</div>

    </div>
    <div style="height:4px"></div>
    <div style="width:100%;height:100%;overflow:auto;font-size: 12px;">
      <table class="dtable">
        <tr>
          <td>开票方</td>
          <td>对接主体</td>
          <td v-if="ywType==1">险种</td>
         
          <td>净利润</td>
        </tr>

      </table>

    </div>


    <div class="inpage">

      sadf
    </div>


    <div class="rowitem">
      <div class="rowlabel">
        <span class="require">*</span>
        开票方
      </div>
      <div class="rowcontent">
        <!-- <el-input size="small" /> -->
        <el-select size="small" v-model="kpf_id" style="width:100%">
          <el-option v-for="(kpf, idx) in KpfList" :key="idx" :label="kpf.kp_title" :value="kpf.id"></el-option>
        </el-select>
      </div>


    </div>

    <div class="rowitem">
      <div class="rowlabel">
        <span class="require">*</span>
        对接主体
      </div>
      <div class="rowcontent">
        <!-- <el-input size="small" /> -->
        <el-select size="small" v-model="custom_id" style="width:100%">
          <el-option v-for="(zt, idx) in ZhutiList" :key="idx" :label="zt.custom_name" :value="zt.id"></el-option>
        </el-select>
      </div>


    </div>

    <div class="rowitem" v-if="ywType==1">
      <div class="rowlabel">
        <span class="require">*</span>
        险种类型
      </div>
      <div class="rowcontent">
        <el-radio-group v-model="bxType">
          <el-radio label="个险">个险</el-radio>
          <el-radio label="财险">财险</el-radio>
          <el-radio label="1车险">车险</el-radio>
        
        </el-radio-group>
      </div>


    </div>



</div>
</template>

<script>

export default {
  data() {
    return {
      tabIdx: 0,
      ywType: 1,
      kpf_id: "",
      custom_id: "",
      bxType:"",//保险类型
      KpfList: [],
      ZhutiList:[]

    }
  },
  mounted() {
    document.title = "政策管理"
    this.getKpF()
    this.getZhuti()
  },
  methods: {
    setCkd(t) {
      this.ywType = t
    },
    getKpF() {
      this.$http.post("/api/sys_kp_company_list").then(res => {
        this.KpfList = res.data
      })
    },
    getZhuti() {
      this.$http.post("/api/sch_customs_list").then(res => {
        this.ZhutiList = res.data
      })
    }

  }
}
</script>

<style scoped>
page {
  overflow-x: hidden;
}

.rowitem {

  width: 90%;
  margin: 0 auto;
  position: relative;
  min-height: 62px;
  border-bottom: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;

}


.rowitem .thinline {
  display: none;
}

.rowcontent {
  padding: 15px 0px;
  position: relative;
  margin-left: 100px;
  line-height: 28px;

}

.rowcontent textarea {
  padding-top: 4rpx;
}

.icon-arrow {
  width: 50rpx;
  height: 50rpx;
  position: absolute;
  top: 30rpx;
  right: 0;
  z-index: 9;
}


.rowlabel {
  width: 100px;
  line-height: 60px;
  float: left;
}

.rowlabel span {
  color: #fff;
}

.require {
  color: orangered !important;
  transform: translateX(-5px);
}


.btnsubmit {
  background-color: #3bb0f2;
  color: #fff;
  width: 300rpx !important;
  border-radius: 40rpx !important;
  height: 80rpx;
  line-height: 80rpx;
}

.dtable,
.dtable tr th,
.dtable tr td {
  border: 1px solid #ccc;
}

.dtable {
  width: 100%;
  min-height: 25px;
  line-height: 25px;
  text-align: center;
  border-collapse: collapse;
  padding: 2px;
}

.tabbox {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
  height: 32px;
  border: 1px solid #1283FF;
}

.tabitem {
  display: inline-block;
  line-height: 20px;
  width: 50%;
  color: #888;
  padding: 6px 0;
}

.tabckd {
  background-color: #1283FF;
  color: #fff;
}
</style>
